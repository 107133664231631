// Menu.js

import React, { useState } from 'react';
import MenuNavBar from '../../Components/MenuNavBar/MenuNavBar';
import './Menu.css';
import Appetizer from '../Appetizer/Appetizer';
import Tiffins from '../Tiffins/Tiffins';
import VegThali from '../Thali/Thali';
import VegCombo from '../VegCombo/VegCombo';
import Pulao from '../Pulao/Pulao';
import Beverage from '../Beverage/Beverage';

function Menu() {
    const [selectedMenuItem, setSelectedMenuItem] = useState('Tiffins'); // State to track selected menu item

    // Function to update selected menu item
    const handleMenuItemClick = (menuItem) => {
        setSelectedMenuItem(menuItem.trim());
    };


    // Render the corresponding component based on the selected menu item
    const renderComponent = () => {
        switch (selectedMenuItem) {
            case 'Appetizer':
                return <Appetizer />;
            case 'Tiffins':
                return <Tiffins />;
            case 'VegThali':
                return <VegThali />;
            case 'VegCombo':
                return <VegCombo />;
            case 'Pulao':
                return <Pulao />;
            case 'Beverage':
                return <Beverage />;
            default:
                return null;
        }
    };


    return (
        <div>
            <div className="menubanner">
                <p className="menuBannerText">{selectedMenuItem} Menu</p>
            </div>
            <div>
                <MenuNavBar handleMenuItemClick={handleMenuItemClick} selectedMenuItem={selectedMenuItem} />
            </div>
            <div className='menuarea'>
                {renderComponent()}
            </div>
        </div>
    );
}

export default Menu;
