import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PrimaryHeader from './Components/Primaryheader/PrimaryHeader';
import Footer from './Components/Footer/Footer';
import NavBar from './Components/NavBar/NavBar';
import ScrollToTop from './Components/Scroll/ScrollTop';
import Home from './Pages/Home/Home';
import Menu from './Pages/Menu/Menu';
import TruckLocator from './Pages/TruckLocator/TruckLocator';
import OrderPickup from './Pages/OrderPickup/OrderPickup';
import OrderDelivary from './Pages/OrderDelivary/OrderDelivary';
import Contact from './Pages/Contact/Contact';
import Combos from './Pages/Thali/Thali';
import Lunch from './Pages/Tiffins/Tiffins';
import Drinks from './Pages/VegCombo/VegCombo';
import BreakFast from './Pages/Appetizer/Appetizer';

function App() {
  return (
    <Router basename="/">
      <div className="App">
        <PrimaryHeader />
        <NavBar />
        <Routes>
          <Route path="/" element={<Navigate to="/Menu" />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Menu" element={<Menu />} />
          <Route path="/TruckLocator" element={<TruckLocator />} />
          <Route path="/OrderPickup" element={<OrderPickup />} />
          <Route path="/OrderDelivary" element={<OrderDelivary />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/BreakFast" element={<BreakFast />} />
          <Route path="/Lunch" element={<Lunch />} />
          <Route path="/Drinks" element={<Drinks />} />
          <Route path="/Combos" element={<Combos />} />
          <Route path="*" element={<Navigate to="/Menu" />} /> {/* Catch-all route */}
        </Routes>
        <Footer />
        <ScrollToTop/>
      </div>
    </Router>
  );
}

export default App;
