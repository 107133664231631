function Drinks() {
    return (
        <div>
            <p>
                Drinks
            </p>
        </div>
    )
};

export default Drinks;