import "./Pulao.css"; 

function Pulao() {
  const pulaoItems = [
    { name: "Chicken Fry Piece Pulav", price: "$3.00" },
    { name: "Paneer Pulav", price: "$3.50" },
    { name: "Soya Pulav", price: "$4.00" },
  ];

  return (
    <div className="menu">
      <div className="menu-section">
        <p>Apprtizer</p>
        <ul>
          {pulaoItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Pulao;
