import "./Beverage.css";

function Beverage() {
  const beverageItems = [
    { name: "Pista Kulfi (Chef Spl)", price: "$2.49" },
    { name: "Mango Kulfi (Chef Spl)", price: "$2.49" },
    { name: "Mango Lassi (Chef Spl)", price: "$2.49" },
  ];
  const teaItems = [
    { name: "Tea(4 OZ)", price: "$1.00" },
    { name: "Tea(8 OZ)", price: "$1.49" },
    { name: "Filter Coffee", price: "$2.49" },
    { name: "Boost", price: "$2.49" },
    { name: "Horlicks", price: "$2.49" },
    { name: "Coke/Pepsi/Sprite", price: "$1.99" },
    { name: "Thumbs Up", price: "$2.49" },
    { name: "Limca", price: "$2.49" },
    { name: "Water", price: "$1.00" },

  ];

  return (
    <div className="menu">
      <div className="menu-section">
        <p>Desserts</p>
        <ul>
          {beverageItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="menu-section">
        <p>Tea</p>
        <ul>
          {teaItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Beverage;
