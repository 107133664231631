export const openingHours = [
    {
        day: 'Monday',
        openTime: "05:00pm",
        closeTime: '10:00pm',
    },
    {
        day: 'Tuesday',
        openTime: "05:00pm",
        closeTime: '10:00pm',
    },
    {
        day: 'Wednesday',
        openTime: "05:00pm",
        closeTime: '10:00pm',
    },
    {
        day: 'Thursday',
        openTime: "05:00pm",
        closeTime: '10:00pm',
    },
    {
        day: 'Friday',
        openTime: "10:00am",
        closeTime: '10:00pm',
    },
    {
        day: 'Saturday',
        openTime: "10:00am",
        closeTime: '10:00pm',
    },
    {
        day: 'Sunday',
        openTime: "10:00am",
        closeTime: '10:00pm',
    }
]