import "./Tiffins.css"; 

function Tiffins() {
  const idliItems = [
    { name: "Idli (3 pieces)", price: "$6.99" },
    { name: "Chitti Idly (10 pieces)", price: "$7.49" },
    { name: "Ghee Idly", price: "$7.49" },
    { name: "Ghee Chitti Idly", price: "$7.49" },
    { name: "Sambar Idly", price: "$8.49" },
    { name: "Sambar Chitti Idly", price: "$8.99" },
    { name: "Karampodi Idly", price: "$7.49" },
    { name: "Karvepaku Idli", price: "$7.49" },
    { name: "Podi Idly", price: "$7.49" },
    { name: "Podi Idly Chitti", price: "$7.49" },
    { name: "Karampodi Chitti idly", price: "$7.49" },
    { name: "Karvepaku Chitti Idli", price: "$7.49" },
    { name: "Ghee Karampodi Idly", price: "$7.99" },
    { name: "Ghee Karvepaku", price: "$7.99" },
    { name: "Ghee Podi Idly", price: "$7.99" },
    { name: "Ghee Karampodi Chitti Idli", price: "$8.49" },
    { name: "Ghee Karvepaku Karam Chitti Idli", price: "$8.49" },
    { name: "Ghee podi Chitti Idli", price: "$8.99" },
  ];

  const dosaItems = [
    { name: "Plain Dosa", price: "$6.99" },
    { name: "Onion Dosa", price: "$7.99" },
    { name: "Ghee Roast Dosa", price: "$7.49" },
    { name: "Masala Dosa", price: "$8.99" },
    { name: "Karam Dosa", price: "$7.99" },
    { name: "Karampodi Dosa", price: "$7.99" },
    { name: "Karivepaku Karam Dosa", price: "$7.99" },
    { name: "Egg Dosa", price: "$8.99" },
    { name: "Onion Karam Dosa", price: "$8.49" },
    { name: "Onion Karampodi Dosa", price: "$8.49" },
    { name: "Onion Karivepaku Dosa", price: "$8.49" },
    { name: "onion Masala Dosa", price: "$9.49" },
    { name: "Ghee Karam Dosa", price: "$8.49" },
    { name: "Ghee Karampodi Dosa", price: "$8.49" },
    { name: "Ghee Karivepaku Dosa", price: "$8.49" },
    { name: "Ghee Masala Dosa", price: "$8.99" },
    { name: "Ghee Karam Masala Dosa", price: "$9.99" },
    { name: "Ghee Karam Onion Dosa", price: "$8.99" },
    { name: "Ghee Roast Onion Dosa", price: "$8.49" },
    { name: "Ghee Karampodi Onion Dosa", price: "$8.99" },
    { name: "Pesarattu Dosa", price: "$7.99" },
    { name: " Upma Pesarattu Dosa", price: "$9.99" },
    { name: " Chocolate Dosa", price: "$8.99" },
  ];

  const pooriItems = [
    { name: "Poori", price: "$8.99" },
    { name: "Poori With chole", price: "$10.99" },
    { name: "Hot Pongal", price: "$7.99" },
    { name: "Vada", price: "$7.99" },
    { name: "Sambar Vada", price: "$8.99" },
    { name: "Mysore Bonda", price: "$7.99" },
  ];

  const wrapsItems = [
    { name: "Chicken Wraps", price: "$6.00" },
    { name: "Paneer Wraps", price: "$6.50" },
  ];

  return (
    <div className="menu">
      <div className="menu-section">
        <p className="item-heading">Idli</p>
        <ul>
          {idliItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="menu-section">
        <p className="item-heading">Dosa</p>
        <ul>
          {dosaItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="menu-section">
        <p className="item-heading" >Poori & More</p>
        <ul>
          {pooriItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>

     {/* <div className="menu-section">
        <p className="item-heading" >Wraps</p>
        <ul>
          {wrapsItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>*/}
    </div>
  );
}

export default Tiffins;
