import "./Appetizer.css"; 

function Appetizer() {
  const appetizerItems = [
    { name: "Garelu", price: "$7.99" },
    { name: "Punugulu", price: "$6.99" },
    { name: "Chitti Punugulu", price: "$7.99" },
    { name: "Onion Pakodi", price: "$5.99" },
    { name: "Mirchi Bajji (3 Pieces)", price: "$5.99" },
    { name: "cut Mirchi", price: "$7.99" },
    { name: "Stuffed Mirchi", price: "$5.99" },
    { name: "Onion Samosa", price: "$4.99" },
    { name: "Potato Samosa", price: "$5.99" },
  ];

  return (
    <div className="menu">
      <div className="menu-section">
      <p className="item-heading">Snacks</p>
        <ul>
          {appetizerItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Appetizer;
